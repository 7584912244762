import React from 'react'


const Contact = () => {
  return (
    <form
      name='contact'
      method='POST'
      action='/success'
      data-netlify='true'
      data-netlify-honeypot='bot-field'
      class='max-w-screen-md '
    >
      <input type='hidden' name='bot-field' />
      <input type='hidden' name='form-name' value='contact' />
      <div class='mb-4'>
        <label className='block font-semibold text-xl mt-5 mb-3' htmlFor='name'>What's your name?<sup className='text-info'>*</sup></label>
        <input className='shadow appearance-none border rounded w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' type='text' name='name' id='name' required />
      </div>
      <div class='mb-4'>
        <label className='block font-semibold text-xl mt-5 mb-3' htmlFor='email'>What's your email address?<sup className='text-info'>*</sup></label>
        <input className='shadow appearance-none border rounded w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' type='email' name='email' id='email' required />
      </div>
      <div class='mb-4'>
        <label className='block font-semibold text-xl mt-5 mb-3' htmlFor='message'>How can we help?<sup className='text-info'>*</sup></label>
        <textarea className='shadow appearance-none border rounded w-full py-4 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name='message' id='message' required></textarea>
      </div>
      <div class='mb-4'>
        <div class='col-sm-offset-2 col-sm-10'>
          <button type='submit' className='button text-green-800 bg-yellow-200 border-green-900 hover:bg-green-700 hover:text-yellow-200'>Send your enquiry</button>
        </div>
      </div>
    </form>
  )
}

export default Contact
