import React from 'react'


const Contact = () => {
  return (
    <form
      name='commissions'
      method='POST'
      action='/success'
      data-netlify='true'
      data-netlify-honeypot='bot-field'
      class='bg-white min-w-full p-3 border rounded-lg'
    >
      <input type='hidden' name='bot-field' />
      <input type='hidden' name='form-name' value='commissions' />
      <div class='mb-4 px-4'>
        <label className='block font-semibold mt-5 mb-1' htmlFor='name'>Name</label>
        <input className='shadow appearance-none border rounded w-full md:w-1/3 py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='name' id='name' required />
      </div>
      <div class='mb-4 px-4'>
        <label className='block font-semibold mt-5 mb-1' htmlFor='email'>Email</label>
        <input className='shadow appearance-none border rounded w-full md:w-1/2 py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='email' name='email' id='email' required />
      </div>
      <div class='mb-4 px-4'>
        <label className='block font-semibold mt-5 mb-1' htmlFor='address'>Postal address <span className='text-gray-600 font-normal'>(optional)</span></label>
        <input className='shadow appearance-none border rounded w-full md:w-2/3 py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='address' id='address' />
      </div>
      <div class='mb-4 px-4 '>
        <label className='block font-semibold mt-5 mb-1' htmlFor='telephone'>Telephone</label>
        <input className='shadow appearance-none border rounded w-full md:w-1/3 py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='tel' name='telephone' id='telephone' required />
      </div>
      <table className="w-full">
        <tbody className="">
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='block font-semibold mb-1' htmlFor='lot1'>Lot No</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot1' id='lot1' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='block font-semibold mb-1' htmlFor='description1'>Description</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description1' id='description1' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='block font-semibold mb-1' htmlFor='price1'>Price</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price1' id='price1' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot2'>Lot No 2</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot2' id='lot2' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description2'>Description 2</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description2' id='description2' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price2'>Price 2</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price2' id='price2' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot3'>Lot No 3</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot3' id='lot3' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description3'>Description 3</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description3' id='description3' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price3'>Price 3</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price3' id='price3' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot4'>Lot No 4</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot4' id='lot4' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description4'>Description 4</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description4' id='description4' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price4'>Price 4</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price4' id='price4' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot5'>Lot No 5</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot5' id='lot5' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description5'>Description 5</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description5' id='description5' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price5'>Price 5</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price5' id='price5' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot6'>Lot No 6</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot6' id='lot6' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description6'>Description 6</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description6' id='description6' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price6'>Price 6</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price6' id='price6' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot7'>Lot No 7</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot7' id='lot7' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description7'>Description 7</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description7' id='description7' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price7'>Price 7</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price7' id='price7' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot8'>Lot No 8</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot8' id='lot8' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description8'>Description 8</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description8' id='description8' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price8'>Price 8</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price8' id='price8' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot9'>Lot No 9</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot9' id='lot9' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description9'>Description 9</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description9' id='description9' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price9'>Price 9</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price9' id='price9' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot10'>Lot No 10</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot10' id='lot10' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description10'>Description 10</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description10' id='description10' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price10'>Price 10</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price10' id='price10' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot11'>Lot No 11</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot11' id='lot11' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description11'>Description 11</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description11' id='description11' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price11'>Price 12</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price11' id='price11' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot12'>Lot No 12</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot12' id='lot12' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description12'>Description 12</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description12' id='description12' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price12'>Price 12</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price12' id='price12' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot13'>Lot No 13</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot13' id='lot13' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description13'>Description 13</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description13' id='description13' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price13'>Price 13</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price13' id='price13' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot14'>Lot No 14</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot14' id='lot14' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description14'>Description 14</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description14' id='description14' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price14'>Price 14</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price14' id='price14' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot15'>Lot No 15</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot15' id='lot15' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description15'>Description 15</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description15' id='description15' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price15'>Price 15</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price15' id='price15' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot16'>Lot No 16</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot16' id='lot16' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description16'>Description 16</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description16' id='description16' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price16'>Price 16</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price16' id='price16' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot17'>Lot No 17</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot17' id='lot17' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description17'>Description 17</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description17' id='description17' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price17'>Price 17</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price17' id='price17' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot18'>Lot No 18</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot18' id='lot18' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description18'>Description 18</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description18' id='description18' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price18'>Price 18</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price18' id='price18' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot19'>Lot No 19</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot19' id='lot19' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description19'>Description 19</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description19' id='description19' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price19'>Price 19</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price19' id='price19' placeholder='Enter maximum bid' />
            </td>
          </tr>
          <tr>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='lot20'>Lot No 20</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='lot20' id='lot20' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/2">
              <label className='sr-only' htmlFor='description20'>Description 20</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='description20' id='description20' />
            </td>
            <td className="p-4 whitespace-no-wrap border-b border-gray-200 w-1/4">
              <label className='sr-only' htmlFor='price20'>Price 20</label>
              <input className='shadow appearance-none border rounded w-full py-3 px-4 text-gray-600 leading-tight focus:outline-none focus:shadow-outline' type='text' name='price20' id='price20' placeholder='Enter maximum bid' />
            </td>
          </tr>

        </tbody>
      </table>

      <div className='mt-6 mb-3 text-center'>
        <button type='submit' className='button text-green-800 bg-yellow-200 border-green-900 hover:bg-green-700 hover:text-yellow-200'>Send Commission Bids</button>
      </div>

    </form>
  )
}

export default Contact
