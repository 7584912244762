import React from 'react'
import { graphql } from 'gatsby'
import Contact from '../components/contact'
import Commission from '../components/commissions'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SliceList from '../components/sliceList'


export const query = graphql`
  query pageQuery($uid: String!, $lang: String!) {
    prismic {
      allPages(uid: $uid, lang: $lang) {
        edges {
          node {
            _meta {
              uid
              lang
            }
            body {
              ... on PRISMIC_PageBodyText {
                type
                primary {
                  text
                }
              }
              ... on PRISMIC_PageBodyCard_deck {
                type
                primary {
                  deckTitle
                }
                fields {
                  image
                  text
                  title
                  button_link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyText_with_button {
                type
                primary {
                  text
                  button_text
                  button_link {
                    _linkType
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyQuote {
                type
                primary {
                  role
                  quote
                  image
                  company
                  author
                }
              }
              ... on PRISMIC_PageBodyHero {
                type
                primary {
                  image
                  title
                  text
                  button_text
                  button_link {
                    _linkType
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyBanner_with_caption {
                type
                primary {
                  image
                  title
                  text
                  button_text
                  button_link {
                    _linkType
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyImage {
                type
                primary {
                  image
                }
              }
              ... on PRISMIC_PageBodyImage_gallery {
                type
                primary {
                  gallery_name
                }
                fields {
                  image
                }
              }
              ... on PRISMIC_PageBodyList {
                type
                label
                primary {
                  listTitle
                }
                fields {
                  list_item_image
                  list_item_text
                  lot
                  price
                  price_achieved
                }
              }
              ... on PRISMIC_PageBodyMap {
                type
                primary {
                  google_maps
                }
              }
              ... on PRISMIC_PageBodyVideo {
                type
                label
                primary {
                  embed_video
                  video_link {
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                  video_poster
                  video_title
                }
              }
            }
          }
        }
      }
    }
  }
`


const RenderBody = ({ page }) => {
  return (
    <div>
      {page.body !== null && page.body.length > 0 && page.body.map((slice, index) => {
        return (
          <React.Fragment key={`slice-${index}`}>
            <SliceList slice={slice} />
          </React.Fragment>
        )
      })}
      {page._meta.uid === 'contact' && (
        <div className='container mx-auto px-4 lg:py-6 lg:px-0'>
          <Contact />
        </div>
      )}
      {page._meta.uid === 'commission-bids' && (
        <div className='container mx-auto mt-1'>
          <Commission />
        </div>
      )}
    </div>
  )
}

const page = props => {
  const doc = props.data.prismic.allPages.edges.slice(0,1).pop();
  if(!doc) return null;

  return(
    <Layout>
      <SEO title={doc.node.meta_title ? (doc.node.meta_title):(doc.node.title)} keywords={doc.node.meta_keywords} description={doc.node.meta_description} lang={doc.node._meta.lang} />
      <RenderBody page={doc.node} />
    </Layout>
  )
}
export default page
